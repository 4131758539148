html, body {
    margin: 0;
    padding: 0;
}

[data-amplify-authenticator] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}